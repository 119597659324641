function dismissModal (event) {
    const modalID = event.getAttribute('data-dismiss');
    if (!modalID) return;

    const modal = document.querySelector(modalID);
    if (!modal) return;

    Magellan.Modal.close(modal);
}

function toggleSignUpModal() {
    const modal = document.querySelector('#sign-in-modal');
    if (!modal) return;
    Magellan.Modal.open(modal);
}

function toggleVendorArticleModal(modalId) {
    const vendorArticleModal = document.querySelector('#vendorArticleModal' + modalId);
    if(!vendorArticleModal) return;
    Magellan.Modal.open(vendorArticleModal);
}

window.Magellan = window.Magellan || {};
window.Magellan.Modal = window.Magellan.Modal || {};

Magellan.Modal.close = function (modal) {
    modal.setAttribute('aria-hidden', 'true');
    modal.classList.remove('show');
    modal.style.display = 'none';

    
    /*document.getElementById(modal.id).previousSibling.remove();
    document.getElementById(modal.id).nextSibling.remove();*/


    document.removeEventListener('focus', Magellan.Modal.trapFocus, true);
    document.removeEventListener('keyup', Magellan.Modal.handleEscape);
};

Magellan.Modal.closeCurrentDialog = function () {
    const currentDialog = Magellan.Modal.getCurrentDialog();

    if (currentDialog) {
        Magellan.Modal.close(currentDialog);
        return true;
    }

    return false;
};

Magellan.Modal.handleEscape = function (event) {
    if (event.key.toLowerCase() === 'escape' && Magellan.Modal.closeCurrentDialog()) {
          event.stopPropagation();
    }
};

Magellan.Modal.isFocusable = function (element) {
    if (element.tabIndex < 0) {
        return false;
    }

    if (element.disabled) {
        return false;
    }

    switch (element.nodeName) {
        case 'A':
            return !!element.href && element.rel != 'ignore';
        case 'INPUT':
            return element.type != 'hidden';
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
            return true;
        default:
            return false;
    }
};

Magellan.Modal.attemptFocus = function (element) {
    if (!Magellan.Modal.isFocusable(element)) {
        return false;
    }

    Magellan.Modal.IgnoreUtilFocusChanges = true;
    try {
        element.focus();
    } catch (e) {
        // continue regardless of error
    }
    Magellan.Modal.IgnoreUtilFocusChanges = false;
    return document.activeElement === element;
};

Magellan.Modal.focusFirstDescendant = function (element) {
    for (let i = 0; i < element.childNodes.length; i++) {
        const child = element.childNodes[i];

        if (
            Magellan.Modal.attemptFocus(child) ||
            Magellan.Modal.focusFirstDescendant(child)
        ) {
            return true;
        }
    }

    return false;
};

Magellan.Modal.focusLastDescendant = function (element) {
    for (let i = element.childNodes.length - 1; i >= 0; i--) {
        const child = element.childNodes[i];

        if (
            Magellan.Modal.attemptFocus(child) ||
            Magellan.Modal.focusLastDescendant(child)
        ) {
            return true;
        }
    }

    return false;
};

Magellan.Modal.getCurrentDialog = function () {
    return document.querySelector('.magellan-modal-wrapper .modal.show');
};

Magellan.Modal.trapFocus = function (event) {
    const currentDialog = Magellan.Modal.getCurrentDialog();

    if (currentDialog.contains(event.target)) {
        currentDialog.lastFocus = event.target;
    } else {
        Magellan.Modal.focusFirstDescendant(currentDialog);
        if (currentDialog.lastFocus === document.activeElement) {
            Magellan.Modal.focusLastDescendant(currentDialog);
        }
        currentDialog.lastFocus = document.activeElement;
    }
};

Magellan.Modal.open = function (modal) {
    modal.setAttribute('aria-hidden', 'false');
    modal.classList.add('show');
   modal.style.display =  '';

    // Bracket the dialog node with two invisible, focusable nodes.
    // While this dialog is open, we use these to make sure that focus never
    // leaves the document even if dialogNode is the first or last node.
    const preDiv = document.createElement('div');
    const preNode = modal.parentNode.insertBefore(
        preDiv,
        modal
    );
    preNode.tabIndex = 0;
    const postDiv = document.createElement('div');
    const postNode = modal.parentNode.insertBefore(
        postDiv,
        modal.nextSibling
    );
    postNode.tabIndex = 0;

    Magellan.Modal.focusFirstDescendant(modal);

    document.addEventListener('focus', Magellan.Modal.trapFocus, true);
    document.addEventListener('keyup', Magellan.Modal.handleEscape);
};

(function() {
    addSignupListener();
    if(Liferay.ThemeDisplay.protectedArticle()) {
        showMandatorySignInModal();
    }
})()

function addSignupListener() {
    const navigationLinks1 = document.querySelectorAll('.magellan-navigation-item');

    if (navigationLinks1) {
        navigationLinks1.forEach(navigationLink => {

            navigationLink.addEventListener('click', (event) => {
                if (!Liferay.ThemeDisplay.isSignedIn())  {
                    event.preventDefault();
                    const targetHref = event.target?.href || event.currentTarget?.href;
                    callSignInModal(targetHref);
                }
            })
        })
    }
}

function showMandatorySignInModal() {

    const targetHref = window.location.pathname;

    callSignInModal(targetHref);

    const closeButton = document.querySelector('#sign-in-modal .modal-close');
    if(closeButton) {
        closeButton.remove();
    }

    document.addEventListener('keyup',function(e){
        if (e.key === "Escape") {
            const modal = document.querySelector('#sign-in-modal');
            modal.style.display = 'block';
        }
    });
}

function callSignInModal(targetHref) {
    const signInButton = document.querySelector('#sign-in-modal .modal-sign-in-button');
    const createAccountButton = document.querySelector('#sign-in-modal .modal-sign-out-button');

    if (signInButton.dataset.originalHref) {
        signInButton.href = signInButton.dataset.originalHref;
    }

    if (createAccountButton.dataset.originalHref) {
        createAccountButton.href = createAccountButton.dataset.originalHref;
    }

    toggleSignUpModal();

    if (targetHref) {
        signInButton.dataset.originalHref = signInButton.href;
        createAccountButton.dataset.originalHref = createAccountButton.href;

        const appendRedirectToSearchParams = (href, targetHref) => {
            const urlObject = new URL(href);
            const urlSearchParams = new URLSearchParams(urlObject.search);
            urlSearchParams.set('redirectURL', targetHref);
            urlObject.search = urlSearchParams.toString();
            return urlObject.toString();
        }

        signInButton.href = appendRedirectToSearchParams(signInButton.href, targetHref);
        createAccountButton.href = appendRedirectToSearchParams(createAccountButton.href, targetHref);

    }
}